// components/LandingPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css'; // We'll create this CSS file next

function LandingPage() {
  const navigate = useNavigate();

  const handleCTAClick = () => {
    navigate('/login'); // Try a Free Mock Exam -- Direct users to login or the mock exam
  };

  return (
    <div className="landing-page">
      {/* Header Section */}
      <header className="landing-header">
        <div>
            <div className='logo'>TestHackAI</div>
            <div className="logo">
            <img src="/testhack_1.webp" alt="TestHack Logo" style={{ width: '100px', height: 'auto', borderRadius: '30%' }} />
            </div>
        </div>
        <nav>
          <button onClick={() => navigate('/login')}>Login</button>
          {/* Add more navigation links if necessary */}
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-text">
          <h1>Master the TOEFL with AI-Powered Practice Exams</h1>
          <p>Affordable, personalized, and effective TOEFL preparation.</p>
          <button onClick={handleCTAClick}>Try a Free Mock Exam</button>
        </div>
        <div className="hero-image">
          {/* You can replace the src with an appropriate image */}
          <img src="/heroImg_2.jpg" alt="hero img" />
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <h2>Why Choose TestHack?</h2>
        <div className="features">
          <div className="feature">
            <h3>AI-Generated Exams</h3>
            <p>Experience unique exams every time, generated by the latest openAI models.</p>
          </div>
          <div className="feature">
            <h3>Instant Feedback</h3>
            <p>Get immediate corrections and insights on your performance.</p>
          </div>
          <div className="feature">
            <h3>Affordable Pricing</h3>
            <p>Practice more for less, with exams starting at just $3.</p>
          </div>
          <div className="feature">
            <h3>Comprehensive Practice</h3>
            <p>Cover all TOEFL sections: Reading, Listening, Speaking, and Writing.</p>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works-section">
        <h2>How It Works</h2>
        <ol>
          <li>Practice with AI-generated TOEFL exams.</li>
          <li>Receive instant corrections and feedback.</li>
          <li>Track your progress and improve your scores.</li>
        </ol>
      </section>

      {/* Testimonials Section (Optional) */}
      {/* Uncomment if you have testimonials
      <section className="testimonials-section">
        <h2>What Our Users Say</h2>
        <div className="testimonials">
          <div className="testimonial">
            <p>"TestHack helped me improve my TOEFL score by 10 points!"</p>
            <h4>- Student A</h4>
          </div>
          {/* ... more testimonials */}
      {/*  </div>
      </section>
      */}

      {/* Pricing Section */}
      <section className="pricing-section">
        <h2>Simple and Affordable Pricing</h2>
        <div className="pricing">
          <div className="pricing-card">
            <h3>Single Mock Exam</h3>
            <p>$3 per exam</p>
            <button onClick={handleCTAClick}>Get Started</button>
          </div>
          {/* Add more pricing options if necessary */}
        </div>
      </section>

      {/* Footer */}
      <footer className="landing-footer">
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} TestHack. All rights reserved.</p>
          <div className="footer-links">
            <a href="/terms">Terms of Service</a>
            <a href="/privacy">Privacy Policy</a>
            {/* Add more footer links if necessary */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
